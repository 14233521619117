.nav-container {
  width: 200px;
  padding: 1rem;
  position: sticky;
  top: 0;
  height: calc(100vh - 120px); /* viewport height - logo area */
  overflow-y: auto;
  z-index: 11;
}

.nav-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 0;
  list-style: none;
  text-align: left;
}

.nav-item {
  position: relative;
  display: inline-block;
}

.nav-label {
  position: relative;
  z-index: 10;
  font-size: 1.5rem;
  font-family: 'Special Elite', cursive;
  color: white;
  padding: 0 1rem;
}

.nav-background {
  position: absolute;
  inset: 0;
  background-color: black;
  transition: all 300ms ease-in-out;
  transform: rotate(0) skew(0);
  opacity: 0;
}

.nav-background.active {
  opacity: 0.8;
  transform: rotate(-2deg) skewX(-6deg);
}

.nav-item:hover .nav-background {
  opacity: 0.8;
  transform: rotate(-2deg) skewX(-6deg);
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: white;
}

/* Mobile styles */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .nav-container {
    width: 100%;
    padding: 0;
    height: auto;
    position: relative;
  }

  .mobile-menu-button {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 100;
  }

  .nav-list {
    position: fixed;
    top: 0;
    right: -100%;
    width: 250px;
    height: 100vh;
    background-color: rgba(20, 20, 20, 0.95);
    padding: 4rem 2rem;
    transition: right 0.3s ease;
    margin: 0;
  }

  .nav-list.open {
    right: 0;
  }


}