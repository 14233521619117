/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

/* Fixed background */
.App::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/public/ensayo.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.content-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  max-width: 100vw;
  padding-bottom: 60px; /* Space for footer */
}

.main-content {
  flex: 1;
  color: white;
  position: relative;
  z-index: 1;
  overflow-y: auto;
  max-height: calc(100vh - 60px - 120px); /* viewport height - footer - logo area */
  margin: 0 auto;
  width: 100%;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

/* Webkit scrollbar styles */
.main-content::-webkit-scrollbar {
  width: 8px;
}

.main-content::-webkit-scrollbar-track {
  background: transparent;
}

.main-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.logo {
  width: 200px;
  margin: 1rem auto;
  display: block;
  position: relative;
  z-index: 2;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  padding: 1rem;
  z-index: 10;
}

.flyer{
  margin-top: 100px;
  width:80%;
  max-width: 300px;
}

.info{
  display: flex;
  justify-content: center;
  align-items: center; 
}

.home{
  margin: 0 auto;
}
.info_container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  max-width: 500px;
  margin-top: 50px;
  padding: 16px;
  background-color: rgba(140, 140, 140, 0.7); 
  border-radius: 10px;
  font-family: 'Special Elite', cursive;
  line-height: 24px
}
.home_info_container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  max-width: 530px;
  margin-top: 50px;
  padding: 16px;
  background-color: rgba(140, 140, 140, 0.7); 
  border-radius: 10px;
  font-family: 'Special Elite', cursive;
  line-height: 24px;
  margin:16px auto
}

.hero_title {
  font-size: 2.5rem;
  font-family: 'Special Elite', cursive;
  color: white;
  padding: 0 1rem;
}

.hero_subtitle {
  font-size: 1.5rem;
  font-family: 'Special Elite', cursive;
  color: white;
  padding: 0 1rem;
}

.social_icons{
  display: flex;
  justify-content: center;
  margin-left: -24px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1; /* This makes all images square */
  border-radius: 8px; /* Optional: adds rounded corners */
}

/* If you want specific breakpoints for different screen sizes */
@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row on mobile */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 images per row on tablets */
  }
}

@media (min-width: 1025px) {
  .image-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 images per row on desktop */
  }
}
.download_section {
  margin-top: 2rem;
}

.download_section h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.download_buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.download_button {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  color: #0066cc;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.download_button:hover {
  color: #003366;
}

.download_icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

/* music page */
.music {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.soundcloud-container {
  width: 100%;
}

.soundcloud-info {
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
  font-weight: 100;
  margin-top: 5px;
}

.soundcloud-info a {
  color: #cccccc;
  text-decoration: none;
}

.soundcloud-info a:hover {
  text-decoration: underline;
}

.music iframe {
  max-width: 100%;
}